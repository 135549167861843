<template>
  <div class="quiz-overview">
    <div
      :style="{ color: gmPrimaryColor }"
      class="sw-block-subtitle text-center"
    >
      {{ $vuetify.lang.t("$vuetify.confirmationHeadline") }}
    </div>
    <div class="sw-mb-5 sw-text text-center">
      {{ $vuetify.lang.t("$vuetify.editInformationText") }}
    </div>
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <!-- answers -->
    <div class="sw-text font-weight-thin">
      {{ $vuetify.lang.t("$vuetify.yourAnswers") }}
    </div>
    <v-row
      v-for="(attribute, i) in transformedUserAttributes"
      :key="`answer-${i}`"
      no-gutters
      class="sw-text"
    >
      <v-col cols="9" class="font-weight-regular">{{ attribute.name }}</v-col>
      <v-col cols="3" class="text-right">
        <a
          :style="{ color: gmSecondaryColor }"
          @click.stop="changeAnswer(attribute)"
          >{{ $vuetify.lang.t("$vuetify.edit") }}</a
        >
      </v-col>
      <template v-if="attribute.type === 'options'">
        <v-col
          v-for="(answer, i) in attribute.answer"
          :key="`answer-${i}`"
          cols="12"
          class="font-weight-light"
        >
          {{ answer.name }}
          <span v-if="answer.price"
            >({{ answer.price / 100 }} {{ groupCurrency }})</span
          >
        </v-col>
      </template>
      <v-col
        v-if="attribute.type === 'varchar'"
        cols="12"
        class="font-weight-light"
      >
        {{ attribute.answer }}
      </v-col>
      <v-col
        v-if="attribute.type === 'text'"
        cols="12"
        class="font-weight-light"
      >
        <template v-if="attribute.answer">
          <nl2br tag="p" :text="attribute.answer" />
        </template>
      </v-col>
      <v-col
        v-if="attribute.type === 'datetime'"
        cols="12"
        class="font-weight-light"
      >
        {{ dateFormattedDate(attribute.answer) }}
      </v-col>
    </v-row>
    <!-- eo answers -->
    <v-divider :color="gmPrimaryColor" class="sw-my-5"></v-divider>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="next"
      >
        <span class="sw-px-2">
          {{ finishBtnText }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    isLoading: false,
    dateFormat: null,
    category: {},
    transformedUserAttributes: [],
  }),
  computed: {
    defaultDateFormat() {
      return process.env.VUE_APP_DATE_FORMAT;
    },
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store.commit("SET_USER", val);
      },
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    plugin() {
      return this.$store?.getters["quiz/plugin"];
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    defaultTaxRate() {
      return this.$store?.getters?.group?.default_tax_rate;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    attribute: {
      get() {
        return this.$store?.getters["quiz/attribute"];
      },
      set(val) {
        this.$store.commit("quiz/SET_ATTRIBUTE", val);
      },
    },
    window: {
      get() {
        return this.$store?.getters["quiz/window"];
      },
      set(val) {
        this.$store?.commit("quiz/SET_WINDOW", val);
      },
    },
    dateFormattedDate() {
      const format = this.dateFormat || this.defaultDateFormat;

      return (date) => moment(date).format(format);
    },
    finishBtnText() {
      if (
        this.plugin.attributes &&
        this.plugin.attributes.questionwizard_finish_button
      ) {
        return this.plugin.attributes.questionwizard_finish_button;
      }

      return this.$vuetify.lang.t("$vuetify.finishQuiz");
    },
  },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val !== 1) {
          return null;
        }

        this.fetchAll();
        this.getDateFormat();
      },
    },
    appLanguage: {
      handler() {
        this.fetchAll();
      },
    },
  },
  methods: {
    async getDateFormat() {
      const response = await this.$http.get(
        `/settings/public/global.date_format`,
      );

      this.dateFormat = response?.data?.data?.value;
    },
    async getQuizCategory() {
      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        {
          params: {
            group_plugin_id: this.plugin?.id,
            per_page: 1,
          },
        },
      );

      if (!response?.data?.data?.[0]) return;

      this.category = response?.data?.data?.[0];
    },
    async listUserAttributes() {
      this.transformedUserAttributes = [];

      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      if (!response?.data?.data) {
        return null;
      }

      const userAttributes = response.data?.data;
      const userAttributesMeta = response.data?.meta || [];

      this.transformedUserAttributes = this.transformAttributes(
        userAttributes,
        userAttributesMeta,
      );
    },
    transformAttributes(attributes, attributeMetaValues) {
      const transformedAttributes = [];

      for (const attribute of attributes) {
        const value = attributeMetaValues[attribute?.slug];

        attribute.meta_value = value;

        if (["varchar", "datetime", "text"].includes(attribute.type)) {
          attribute.answer = value || "";
        }

        if (attribute.type === "options") {
          if (!attribute?.is_collection) {
            attribute.answer = attribute.options.filter(
              (option) => value === option.id,
            );
          } else {
            attribute.answer = attribute.options.filter((option) =>
              value.includes(option.id),
            );
          }
        }

        transformedAttributes.push(attribute);
      }

      return transformedAttributes;
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getQuizCategory();
        await this.listUserAttributes();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    changeAnswer(attribute) {
      this.attribute = attribute;
      this.user.attributes[attribute.slug] = attribute.meta_value;
      this.window = 0;
    },
    async completeQuiz() {
      const params = {
        category_id: this.category.id,
      };

      await this.$http.post(
        `groups/${this.groupId}/users/${this.user.id}/complete_attribute_category`,
        params,
      );
    },
    async next() {
      try {
        this.isLoading = true;

        await this.completeQuiz();

        this.isLoading = false;

        this.window = 2;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
