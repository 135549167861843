<template>
  <div class="quiz-questions">
    <div class="sw-block-subtitle text-center">
      {{ attribute.name }}
    </div>
    <v-card flat max-width="356" class="sw-mx-auto">
      <ValidationObserver ref="attributeValidationObserver">
        <v-row class="no-gutters">
          <v-col cols="12"> </v-col>
          <v-col
            v-if="attribute.type === 'options' && attribute.is_collection"
            cols="12"
            class="custom-select"
          >
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="attribute.name"
              v-slot="{ errors }"
            >
              <MultiSelect
                v-model="userAttributeValues[attribute.slug]"
                :items="attribute.options"
                :errorMessages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="attribute.type === 'options' && !attribute.is_collection"
            cols="12"
            class="custom-select"
          >
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="attribute.name"
              v-slot="{ errors }"
            >
              <v-autocomplete
                v-model="userAttributeValues[attribute.slug]"
                :errorMessages="errors[0]"
                :items="filterAvailableOptions(attribute.options)"
                :menu-props="{ closeOnContentClick: true }"
                item-text="name"
                item-value="id"
                :placeholder="$vuetify.lang.t('$vuetify.chooseAnswer')"
                flat
                background-color="#f2f2f2"
                class="custom-select rounded-lg registration-field-rounded"
                solo
                append-icon="mdi-magnify"
              >
                <template v-slot:selection="{ item }">
                  <span class="mr-2">{{ item.name }}</span>
                  <span v-if="item.price" class="mr-2"
                    >({{ item.price / 100 }} {{ groupCurrency }})</span
                  >
                </template>
                <template slot="item" slot-scope="data">
                  <span>{{ data.item.name }}</span>
                  <span v-if="data.item.price" class="ml-2"
                    >({{ data.item.price / 100 }} {{ groupCurrency }})</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col v-if="attribute.type === 'varchar'" cols="12">
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="attribute.name"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="userAttributeValues[attribute.slug]"
                :errorMessages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.writeAnswer')"
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col v-if="attribute.type === 'text'" cols="12">
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="attribute.name"
              v-slot="{ errors }"
            >
              <v-textarea
                v-model="userAttributeValues[attribute.slug]"
                :errorMessages="errors[0]"
                :placeholder="$vuetify.lang.t('$vuetify.writeAnswer')"
                no-resize
                flat
                background-color="#f2f2f2"
                class="rounded-lg registration-field-rounded"
                solo
              ></v-textarea>
            </ValidationProvider>
          </v-col>
          <v-col v-if="attribute.type === 'datetime'" cols="12">
            <ValidationProvider
              rules="required"
              mode="passive"
              :name="attribute.name"
              v-slot="{ errors }"
            >
              <date-picker
                :errorMessages="errors[0]"
                v-model="userAttributeValues[attribute.slug]"
              >
              </date-picker>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-card>
    <div class="sw-pt-7 text-center">
      <v-btn
        rounded
        large
        depressed
        class="
          sw-mx-2
          text-none
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        :disabled="currentAttributeIndex === 0"
        @click="getPreviousAttribute"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.back") }}
        </span>
      </v-btn>
      <v-btn
        rounded
        large
        depressed
        :style="{ backgroundColor: gmSecondaryColor }"
        class="
          sw-mx-2
          text-none
          white--text
          sw-rounded
          sw-height-48px
        "
        :loading="isLoading"
        @click="updateUserAttribute"
      >
        <span class="sw-px-2">
          {{ $vuetify.lang.t("$vuetify.continue") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/CustomFields/MultiSelect.vue";
import DatePicker from "@/components/CustomFields/DatePicker.vue";

export default {
  data: () => ({
    isLoading: false,
    category: {},
    relatedAttributesData: [],
    relatedAttributesMeta: {},
  }),
  computed: {
    user: {
      get() {
        return this.$store?.getters?.user;
      },
      set(val) {
        this.$store?.commit("SET_USER", val);
      },
    },
    userAttributeValues() {
      return this.user?.attributes || {};
    },
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    groupCurrency() {
      return this.$store?.getters?.group?.currency;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    plugin: {
      get() {
        return this.$store?.getters["quiz/plugin"];
      },
      set(val) {
        this.$store.commit("quiz/SET_PLUGIN", val);
      },
    },
    attribute: {
      get() {
        return this.$store?.getters["quiz/attribute"];
      },
      set(val) {
        this.$store.commit("quiz/SET_ATTRIBUTE", val);
      },
    },
    currentAttributeIndex() {
      return this.relatedAttributesData.findIndex(
        (el) => el.id === this.attribute.id,
      );
    },
    window: {
      get() {
        return this.$store?.getters["quiz/window"];
      },
      set(val) {
        this.$store.commit("quiz/SET_WINDOW", val);
      },
    },
  },
  components: {
    MultiSelect,
    DatePicker,
  },
  watch: {
    window: {
      immediate: true,
      handler(val) {
        if (val === 0) this.fetchAll();
      },
    },
  },
  methods: {
    filterAvailableOptions(value) {
      if (!value) return [];

      return value.filter((el) => el.usage_available !== 0);
    },
    async getQuizCategory() {
      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/categories`,
        {
          params: {
            group_plugin_id: this.plugin?.id,
            per_page: 1,
          },
        },
      );

      if (!response?.data?.data?.[0]) return;

      this.category = response?.data?.data?.[0];
    },
    async listRelatedAttributes() {
      const params = {
        category_id: this.category.id,
        lang: this.appLanguage,
        per_page: 10000,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/list_related`,
        { params },
      );

      this.relatedAttributesData = response?.data?.data;
      this.relatedAttributesMeta = response?.data?.meta;
    },
    async getPreviousAttribute() {
      this.$refs.attributeValidationObserver.reset();

      try {
        this.isLoading = true;

        await this.listRelatedAttributes();

        this.isLoading = false;

        if (this.currentAttributeIndex === 0) return;

        const previousAttribute = this.relatedAttributesData[
          this.currentAttributeIndex - 1
        ];

        if (!previousAttribute || !previousAttribute.id) {
          this.attribute = this.relatedAttributesData[0];
        } else {
          this.attribute = previousAttribute;
        }

        const slug = this.attribute?.slug;

        this.user.attributes[slug] = this.relatedAttributesMeta[slug];

        this.$vuetify.goTo(".quiz-page");
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async nextUserAttribute() {
      const params = {
        only_empty: 1,
        category_id: this.category.id,
        lang: this.appLanguage,
      };

      const response = await this.$http.get(
        `/groups/${this.groupId}/users/attributes/next`,
        { params },
      );

      if (!response?.data?.data?.id) {
        this.attribute = {};
        this.window = 1;
      } else {
        this.attribute = response.data.data;
      }
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getQuizCategory();
        await this.listRelatedAttributes();

        if (!this.attribute?.id) {
          await this.nextUserAttribute();
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    async updateUserAttribute() {
      try {
        const isValid = await this.$refs.attributeValidationObserver.validate();

        if (!isValid) return;

        this.isLoading = true;

        const slug = this.attribute.slug;
        const value = this.userAttributeValues[slug];

        const specs = { [slug]: value };

        await this.$http.post(`/auth/quick_update`, specs);
        await this.nextUserAttribute();

        this.$vuetify.goTo(".quiz-page");

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
